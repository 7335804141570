/*=========================================================================================
  File Name: moduleMedicalWebinarMutations.js
  Description: MedicalWebinar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.medicalWebinars.unshift(item)
  },
  SET_MedicalWebinar(state, medicalWebinars) {
    state.medicalWebinars = medicalWebinars
  },
  UPDATE_MedicalWebinar(state, MedicalWebinar) {
    const MedicalWebinarIndex = state.medicalWebinars.findIndex((p) => p.ID == MedicalWebinar.ID)
    Object.assign(state.medicalWebinars[MedicalWebinarIndex], MedicalWebinar)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.medicalWebinars.findIndex((p) => p.ID == itemId)
    state.medicalWebinars.splice(ItemIndex, 1)
},
}
