<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>
        {{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} ITEM
      </h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new"
      :settings="settings"
    >
      <div class="p-6">
        <!-- NAME -->
        <vs-input
          :label="$t('NameEn')"
          v-model="medicalWebinar.NameEN"
          class="mt-5 w-full"
          name="nameen"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('nameen')">{{
          errors.first("name_en")
        }}</span>

        <vs-input
          :label="$t('NameAr')"
          v-model="medicalWebinar.NameAR"
          class="mt-5 w-full"
          name="namear"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('namear')">{{
          errors.first("name_ar")
        }}</span>
 <div class="mt-5"> 
       <label for="" class="vs-input--label">Date</label>
       <!-- <datepicker
            placeholder="Date"
            v-model="medicalWebinar.Date"
            class="  w-full"
            name="from"
          ></datepicker> -->
             <flat-pickr
                :config="config"
                class="w-full"
                v-model="medicalWebinar.Date"
                name="Date"
              ></flat-pickr>
      </div>
         <vs-input
          label="Price"
          v-model="medicalWebinar.Price"
          class="mt-5 w-full"
          name="Price"
          v-validate="'required'"
          type="number"
        />
        <span class="text-danger text-sm" v-show="errors.has('Price')">{{
          errors.first("Price")
        }}</span>
      
     

 <div class="mt-5 flex-1" >
                    <label class="text-sm opacity-75"
                      >Currency
                    </label>
                    <v-select
                      label="Name"
                      class="w-full"
                      v-model="medicalWebinar.CurrencyID"
                      :options="currencies"
                      :reduce="ID => ID.ID"
                    />
                  </div>

       <div class="mt-5 flex-1" >
                    <label class="text-sm opacity-75"
                      >Doctor
                    </label>
                    <v-select
                      label="Name"
                      class="w-full"
                      v-model="medicalWebinar.DoctorID"
                      :options="doctors"
                      :reduce="ID => ID.ID"
                    />
                  </div>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">{{
        $t("Save")
      }}</vs-button>
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >{{ $t("Cancel") }}</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
// import Datepicker from "vuejs-datepicker";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },

  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
       this.medicalWebinar = JSON.parse(
          JSON.stringify(this.data)
        );
      
      }
    }
  },

  data() {
    return {
      PhotoPath: "",
       config: {
        dateFormat: "Y-m-d H:i ",
        enableTime: true,
        minDate: new Date()
      },
      medicalWebinar:{},
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },

  computed: {
   doctors() {
      return this.$store.state.DoctorList.doctors;
    },
    currencies() {
      return this.$store.state.CurrencyList.currencies;
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
         
        }
      }
    },
    isFormValid() {
      return !this.errors.any() && this.medicalWebinar;
    }
  },

  methods: {
    
    initValues() {
      if (this.data.ID) return;
      this.medicalWebinar={};
    },

    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.medicalWebinar.ID !== null && this.medicalWebinar.ID >= 0) {
            this.$store
              .dispatch("ModuleMedicalList/UpdateMedicalWebinar", this.medicalWebinar)
              .catch(err => {
                console.error(err);
                window.showError(err.response.data.message);
              });
          } else {
            delete this.medicalWebinar.ID;
           
            this.$store
              .dispatch("ModuleMedicalList/AddMedicalWebinar", this.medicalWebinar)
              .catch(err => {
                console.error(err);
                window.showError(err.response.data.message);
              });
          }
          this.$emit("closeSidebar");
          this.initValues();
        }
      });
    }
  },

  components: {
    VuePerfectScrollbar,
    // Datepicker,
    flatPickr,

  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
